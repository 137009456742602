.board-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: rgb(204, 132, 84);
}

.controls {
  margin-bottom: 20px;
  text-align: center;
}

.board-wrapper {
  position: relative;
  width: 50vw;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  overflow: hidden;
}

/* 
.board {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgb(55, 124, 214);
} */

.board {
  position: absolute;
  top: auto;
  left: auto;
  width: 152.5px;
  height: 150px;
  background-color: rgb(55, 124, 214);
  border: 4px solid white;
  transform: scale(2.8);
  transform-origin: center center;
}

.board::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: white;
}

.point {
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: red;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
