.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.flex {
  display: flex;
  flex-direction: row;
}

.flex button {
  display: flex;
}

button {
  padding: 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  display: block;
  margin: 10px;
  cursor: pointer;
}

.save-btn {
  background-color: rgb(92, 221, 92);
  margin-top: 10px;
}

.close-btn {
  border-radius: 50%;
  border: 2px solid rgb(78, 76, 76);
  padding: 5px 8px;
  background-color: white;
  position: absolute;
  right: 0;
  top: 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
}

.App-link {
  color: #61dafb;
}


.popup {
  position: absolute;
  position: fixed;
  background-color: white;
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  width: 70vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 40vw;
  height: 80vh;
}

.popup1,
.popup2 {
  padding: 5px;
}

.popup1 div h2{
  margin-top: 0;
}

.popup2 {
  width: 30w;
}

.popup2 div {
  width: 30vw;
}


.flex input[type="text"] {
  width: 80%;
  text-align: center;
  padding: 10px;
  margin: 0 auto;
}

.flex {
  align-items: center;
}

ul {
  padding: 10px 20px;
  margin: 0;
  max-height: 200px;
  min-height: 190px;
  overflow-y: scroll;
}

li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

li label {
  display: flex;
  text-align: left;
  width: 100%;
  align-items: center;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.draggable-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.draggable-item {
  padding: 1px 5px;
  margin: 2px 0px;
  border: 1px solid #282c34;
  box-shadow: 0px 0px 5px grey;
  z-index: 2;
  display: flex;
  width: 90%;
  font-size: 1rem;
  justify-content: space-between;
  text-align: left;
}

.draggable-item button {
  right: 0;
  padding: 8px;
  margin: 2px;
}

.sessions {
  display: flex;
}

.session-list {
  padding: 20px;
  background-color: white;
  color: black;
  margin: 10px;
}

.flex select {
  padding: 4px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  font-size: 16px;
  cursor: pointer;
}

@media screen and (max-width:1000px) {
  .popup {
    width: 90vw;
  }

  .popup1 {
    width: 50vw;
    height: 80vh;
  }

  .popup1,
  .popup2 {
    padding: 5px;
  }

  .popup2 {
    width: 40vw;
  }

  .popup2 div {
    width: 40vw;
  }
}

@media screen and (max-width:1000px) {
  .popup {
    width: 90vw;
  }

  .popup1 {
    width: 50vw;
    height: 80vh;
  }

  .popup1,
  .popup2 {
    padding: 5px;
  }

  .popup2 {
    width: 40vw;
  }

  .popup2 div {
    width: 40vw;
  }
}


@media screen and (max-width:750px) {
  .popup {
    flex-direction: column-reverse;
    width: 80vw;
    height: 90vh;
    padding: 0px 10px;
    align-items: center;
  }

  .popup1 {
    width: 80vw;
    height: 50vh;
    padding: 0px 10px;
  }

  .popup1,
  .popup2 {
    padding: 2px;
  }

  .popup2 {
    width: 100%;
    height: 50vh;
  }

  .popup2 div {
    width: 100%;
  }

  ul {
    max-height: 150px;
  }

  h1,
  h2,
  h3 {
    font-size: 1rem;
  }

  button {
    margin: 3px 10px;
    padding: 10px;
  }
}

@media screen and (max-width:350px) {
  .popup {
    flex-direction: column-reverse;
    width: 90vw;
    height: 90vh;
    padding: 0px 10px;
    align-items: center;
  }

  .popup1,
  .popup2 {
    width: 90vw;
  }
}.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.flex {
  display: flex;
}

.popup2 .flex{
  flex-direction: column;
}

.flex button {
  display: flex;
}

button {
  padding: 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  display: block;
  margin: 10px;
  cursor: pointer;
}

.save-btn {
  background-color: rgb(92, 221, 92);
  margin-top: 10px;
}

.home-close-btn {
  border-radius: 30%;
  border: 2px solid rgb(78, 76, 76);
  padding: 0%;
  background-color: white;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-close-btn svg {
  width: 40px;
  height: 40px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
}

.App-link {
  color: #61dafb;
}


.popup {
  position: absolute;
  position: fixed;
  background-color: white;
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  width: 70vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 40vw;
  height: 80vh;
}

.popup1,
.popup2 {
  padding: 5px;
}

.popup2 {
  width: 30w;
}

.popup2 div {
  width: 30vw;
}


.flex input[type="text"] {
  width: 80%;
  text-align: center;
  padding: 10px;
  margin: 5px auto;
  border-radius: 5px;
  outline: none;
  inset: none;
  border: 2px solid grey;
}

.flex {
  align-items: center;
}

ul {
  padding: 10px 20px;
  margin: 0;
  max-height: 200px;
  overflow-y: scroll;
}

li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

li label {
  display: flex;
  text-align: left;
  width: 100%;
  align-items: center;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.draggable-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.draggable-item {
  padding: 1px 5px;
  margin: 2px 0px;
  border: 1px solid #282c34;
  box-shadow: 0px 0px 5px grey;
  z-index: 2;
  display: flex;
  width: 90%;
  font-size: 1rem;
  justify-content: space-between;
  text-align: left;
}

.draggable-item button {
  right: 0;
  padding: 8px;
  margin: 2px;
}

.draggable-item input[type="number"] {
  width: 50px;
  padding: 5px;
  font-size: 14px;
  text-align: center;
}

.draggable-item input[type="number"] ::-webkit-outer-spin-button,
.draggable-item input[type="number"] ::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.draggable-item input[type=number] {
  -moz-appearance: textfield;
}

.sessions {
  display: flex;
}

.session-list {
  padding: 20px;
  background-color: white;
  color: black;
  margin: 10px;
}

.flex select {
  padding: 4px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  font-size: 16px;
  cursor: pointer;
}


.session{
  /* height: 100px; */
  display: flex;
  overflow-x: scroll;
  flex-direction: row;
}

.session div{
  margin: 5px;
  padding: 5px;
  max-height: 80px;
  border-radius: 10px;
  min-width: 220px;
  max-width: max-content;
  background: #5f5f6125;
  position: relative;
  cursor: pointer;
}

.session div > * {
  margin: 0;
  flex-wrap: nowrap;
}

.deleteSession{
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.newSessionbtn{
  padding: 8px;
  margin: 0px 10px;
}

.popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(14, 14, 15, 0.959);
  z-index: 5;
}

.delete-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 20px 20px rgba(7, 7, 7, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.delete-popup button {
  margin: 10px;
  padding: 10px 20px;
}

.buttons-div{
  overflow-x: scroll;
}


.buttons-div{
  /* text-wrap: nowrap; */
  padding: 5px;
  text-align: center;
}

.buttons-div > button{
  min-width: 120px;
  max-width: max-content;
}

@media screen and (max-width:1000px) {
  .popup {
    width: 90vw;
  }

  .popup1 {
    width: 50vw;
    height: 80vh;
  }

  .popup1,
  .popup2 {
    padding: 5px;
  }

  .popup2 {
    width: 40vw;
  }

  .popup2 div {
    width: 40vw;
  }
}

@media screen and (max-width:1000px) {
  .popup {
    width: 90vw;
  }

  .popup1 {
    width: 50vw;
    height: 80vh;
  }

  .popup1,
  .popup2 {
    padding: 5px;
  }

  .popup2 {
    width: 40vw;
  }

  .popup2 div {
    width: 40vw;
  }
}


@media screen and (max-width:750px) {
  .popup {
    flex-direction: column-reverse;
    width: 90vw;
    height: 90vh;
    padding: 0px 10px;
    align-items: center;
  }

  .popup1 {
    width: 80vw;
    height: 50vh;
    padding: 0px 10px;
  }

  .popup1,
  .popup2 {
    padding: 2px;
  }

  .popup2 {
    width: 100%;
    height: 50vh;
  }

  .popup2 div {
    width: 100%;
  }

  ul {
    max-height: 100px;
    min-height: 100px;
  }

  h1,
  h2,
  h3 {
    font-size: 1rem;
  }

  button {
    margin: 3px 10px;
    padding: 10px;
  }

  .flex{
    flex-wrap: wrap;
  }
  .delete-popup{
    width: 80vw;
  }
}

@media screen and (max-width:350px) {
  .popup {
    flex-direction: column-reverse;
    width: 99vw;
    height: 100vh;
    padding: 0px 10px;
    align-items: center;
  }

  .popup1,
  .popup2 {
    width: 100vw;
  }

  .buttons-div{
    /* text-wrap: wrap; */
  }

  .buttons-div{
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.drill-container{
  display: flex;
  flex-direction: row;
  overflow-wrap: break-word;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.drill{
  padding: 20px;
  width: 300px;
  background-color: #d8cb955b;
  margin: 10px;
  text-align: left;
}


.loading-spinner {
  margin: 0 auto;
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.api-button {
  padding: 10px 15px;
  margin: 0 5px 5px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.api-button.active {
  background-color: rgb(128, 212, 212);
  transform: scale(1.05);
  box-shadow: 0 8px 8px rgba(83, 83, 83, 0.1);
}

/* Create Session Style START */

.appContainer {
  width: 80%; /* Container takes 80% of the viewport width */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Space between the header and the blocks */
}

.sessionContainer {
  display: flex;
  justify-content: space-between;
  width: 100%; /* The sessionContainer takes 100% of the appContainer width */
  flex-wrap: wrap;
}

.block {
  flex: 1 1 49%; /* Each block takes about 49% of the sessionContainer width */
  box-sizing: border-box;
  margin: 0.5%;
  border-radius: 15px; /* Add border-radius for rounded corners */
  padding: 20px; /* Optional: Add padding inside the blocks for better appearance */
}

@media (max-width: 768px) {
  .block {
    flex: 1 1 100%;
    margin: 0 0 1rem 0;
  }
}

.block1 {
  background-color: lightblue;
}

.block2 {
  background-color: lightcoral;
}

.vertical-stack {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.vertical-stack input {
  width: 80%;
  align-self: center;
  padding: 10px;
  margin-bottom: 10px;
}

.text-display {
  text-align: center;
  margin-bottom: 10px;
  color: #282c34;
}

.highlight {
  font-weight: bold;
  color: yellow; /* Highlight color */
}

.saved-sessions {
  height: 25%; /* Reduce the height to 1/4 of the current height */
  overflow-y: auto; /* Allow vertical scrolling if content exceeds the height */
}

.saved-sessions-heading {
  margin: 0 0 10px; /* Space below the heading */
  font-weight: bold;
  color: black; /* Set heading color to black */
}

.sessionItem {
  display: flex;
  justify-content: space-between;
  /*align-items: center;*/
  /*padding: 10px;*/
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #fff;
  cursor: pointer;
}

.sessionItem:hover {
  background-color: #f0f0f0;
}

.sessionContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sessionTitle {
  font-weight: bold;
  color: gray;
  padding-left: 5px;
  justify-content: left !important;
}

.sessionDescription {
  font-size: smaller;
  color: gray;
  padding-left: 5px;
  justify-content: left !important;
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  color: red;
  padding: 0;
}

.delete-button svg {
  width: 20px;
  height: 20px;
}

.selectedDrills {
  color: #282c34;
  margin-bottom: 0 !important;
}

.variation {
  margin-top: 10px;
}

.variationSelect {
  margin-left: 5px;
}

.drillList {
  background-color: #f3f3f3;
  margin: 10px 0 10px 0;
  border-radius: 5px;
  border-color: #ccc;
}

.drillLabel, .itemTitle {
  color: #282c34;
  font-size: small;
}

.section-separator {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 20px 0;
}

.buttonDiv {
  display: flex;
  justify-content: center; /* Center align horizontally */
  align-items: center; /* Center align vertically */
  width: 100%; /* Optional: make the div take full width */
}

.save-btn,
.api-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.custom-drill-button, .newSessionbtn {
  background-color: #483d8b; /* Different color for the Create Custom Drill button */
  color: white; /* Text color */
  border: none; /* Remove border */
}

.custom-drill-button:hover, .newSessionbtn:hover {
  background-color: rgb(58, 55, 73); /* Darker shade on hover */
}

/* Create Session Style END */

/* Create Drill Style START */
.block3 {
  background-color: lightblue;
}

.headerText {
  color: #282c34;
  text-align: left;
  padding-left: 10px;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.buttonContainer {
  display: flex;
  justify-content: space-between; /* Space the buttons evenly */
  margin-top: 20px;
}

.levelBoxStyle {
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 5px;
  border-width: 1px;
  background-color: whitesmoke;
}

.directionBoxStyle {
  width: 95%;
  justify-content: center;
}
/* Create Drill Style END */
